{
  "Starts": "Starter",
  "Ends": "Slutter",
  "View Ad": "Se Annonse",
  "More Information": "Mer Informasjon",
  "View": "Se på",
  "Bookmark your Search": "Bokmerk dette Søket",
  "Bookmark and name your search result for easy access later. You will find the bookmarked searches in the main menu.": "Legg til bokmerke og navngi søkeresultatet. Du finner bokmerkede søk i hovedmenyen.",
  "Name your search result": "Gi ditt søkeresultat et navn",
  "Save Bookmark": "Lagre Bokmerke",
  "Bookmarks": "Bokmerker",
  "Save": "Lagre",
  "Edit": "Redigere",
  "Search name": "Søk navn",
  "Keep Bookmark": "Behold Bokmerke",
  "Remove Bookmark": "Fjern Bokmerke",
  "Please enable location services for {{browserName}} and Website.": "Aktiver posisjonstjenester for {{browserName}} og nettstedet.",
  "Current Location": "Nåværende Plassering",
  "Change Location": "Bytt Sted",
  "Search Location": "Søk Sted",
  "Couldn't find your location": "Kan ikke finne ikke din posisjon",
  "Show me how to fix it": "Vis meg hvordan jeg fikser dette",
  "Ok": "Ok",
  "Enable Location": "Skru på Stedstjenester",
  "Please make sure to enable location services for Safari and Website.": "Vennligst sjekk at posisjonstjenester er skrudd på for Safari og nettstedet.",
  "Enable for Safari": "Skru på for Safari",
  "Open Phone Settings": "Åpne Innstillinger",
  "Open Privacy": "Åpne Personvern",
  "Privacy": "Personvern",
  "Open Location Services": "Åpne Stedstjenester",
  "Location Services": "Stedstjenester",
  "Off": "Avslå",
  "Activate Location Services": "Aktiver Stedstjenester",
  "Scroll Down and Tap Safari Websites": "Rull ned og trykk på Safari-nettsteder",
  "Safari Websites": "Safari-nettsteder",
  "Never": "Aldri",
  "Activate While Using the App": "Velg Når appen er i bruk",
  "While Using the App": "Når appen er i bruk",
  "Activate Precise Location": "Aktiver Nøyaktig Posisjon",
  "Precise Location": "Nøyaktig Posisjon",
  "Did it": "Utført",
  "Enable for Website": "Aktiver for Nettsted",
  "Scroll Down and Tap Safari": "Rull ned og trykk på Safari",
  "Safari": "Safari",
  "Scroll Down and Tap Location": "Rull ned og trykk på Sted",
  "Location": "Sted",
  "When LOCATION ACCESS ON": "Når STEDSTILGANG PÅ",
  "has the entry below": "har oppføringen nedenfor",
  "Select it and Allow Location": "Velg den og Tillat plassering",
  "Allow": "Tillat",
  "OTHER/ALL WEBSITES": "ANDRE/ALLE NETTSIDER",
  "Allow Location": "Tillat Plassering",
  "Please make sure to enable location services for Website and Chrome.": "Sørg for å aktivere posisjonstjenester for Nettstedet og Chrome.",
  "Open Chrome Menu": "Åpne Chrome Menyen",
  "Tap Settings ": "Trykk på Innstillinger",
  "Settings": "Innstillinger",
  "Scroll Down and Tap Site settings": "Rull ned og trykk på Nettstedsinnstillinger",
  "Site Settings": "Nettstedsinnstillinger",
  "Tap Location": "Trykk på Sted",
  "Ask first": "Spør først",
  "Ask before allowing sites to know your location (Recomended)": "Spør før du lar nettsteder vite posisjonen din (anbefalt)",
  "Enable for Chrome": "Aktiver for Chrome",
  "Tap App permissions": "Trykk på Apptillatelser",
  "App permissions": "App-tillatelser",
  "Scroll Down and Tap Chrome": "Rull ned og trykk på Chrome",
  "Chrome": "Chrome",
  "Activate Allow": "Aktiver Tillat",
  "Allow all the time": "Tillat hele tiden",
  "or allow while using the app": "eller tillat mens du bruker appen",
  "Allow while using the app": "Tillat mens du bruker appen",
  "The OS is not supported": "Operativsystemet støttes ikke",
  "Well done! That was a chore, but now you can enjoy the App!": "Bra gjort! Det var et ork, men nå kan du nyte appen!",
  "Use the App": "Bruk Appen",
  "Invalid Link. Contact Customer Service.": "Ugyldig lenke. Kontakt kundeservice.",
  "Verified E-mail": "Verifisert e-post",
  "Your E-mail {{email}} has been verified.": "E-posten din {{email}} er bekreftet.",
  "Are you sure you want to Unbookmark": "Er du sikker på at du vil fjerne bokmerket",
  "Confirm": "Bekreft",
  "Accept to create a profile.": "Godta for å opprette en profil.",
  "Create Profile": "Lag Profil",
  "To BROVS ads, products and services you need to create a Profile.": "To BROVS ads, products and services you need to create a Profile.",
  "Gender *": "Kjønn *",
  "Female": "Dame",
  "Male": "Mann",
  "Create Profile Now": "Lag Profil Nå",
  "Required": "Obligatorisk",
  "Invalid E-mail.": "Ugyldig E-post.",
  "Minimum password length is 8 characters.": "Minste passordlengde er 8 tegn.",
  "Repeat Password doesn't match.": "Repeat Password samsvarer ikke.",
  "Accept to create a Profile.": "Godta for å opprette en Profil.",
  "Address is required.": "Adresse er påkrevd.",
  "First Name": "Fornavn",
  "First Name *": "Fornavn *",
  "Last Name": "Etternavn",
  "Last Name *": "Etternavn *",
  "Mobile Number *": "Mobilnummer *",
  "Continue": "Fortsett",
  "Type your E-mail": "Skriv inn din E-post",
  "E-mail": "E-post",
  "Don't have an E-mail?": "Har du ikke en E-post?",
  "Type Password": "Skriv inn Passord",
  "Create Password *": "Lag Passord *",
  "Repeat Password": "Gjenta Passord",
  "Home Location *": "Hjemsted *",
  "Home address is used to display nearby Businesses and Ads.": "Hjemmeadresse brukes til å vise virksomheter og annonser i nærheten.",
  "Are you sure you want to cancel Sign Up?": "Er du sikker på at du vil avbryte registreringen?",
  "Verify Mobile Number": "Bekreft Mobilnummer",
  "You will receive a SMS with a Short Code to verify your Mobile Number.": "Du vil motta en SMS med en kortkode for å bekrefte mobilnummeret ditt.",
  "Mobile Number": "Mobilnummer",
  "Is your Mobile Number below correct?": "Er ditt Mobilnummer nedenfor riktig?",
  "Edit if not correct.": "Rediger hvis det ikke er riktig.",
  "Check your SMS for Short Code.": "Sjekk SMS -en din for kortkode.",
  "Short Code": "Kortkode",
  "Type Short Code": "Skriv Kortkode",
  "Didn't receive a Short Code?": "Mottok du ikke en Kortkode?",
  "Resend Code": "Send Kode på nytt",
  "Save Food & Money": "Spar Mat og Penger",
  "Ads": "Annonser",
  "Places": "Steder",
  "Searches": "Søk",
  "End Of Day Unsold Food And Beverages": "Slutt på dagen Usolgt mat og drikke",
  "All Categories": "Alle Kategorier",
  "Choose <1>Brands</1> from the list below.": "Velg <1>merker</1> fra listen under.",
  "Filters": "Filtre",
  "Apply": "Legg til",
  "Search Brands": "Søk etter Merker",
  "Brands": "Merker",
  "Filter by <1>Brands</1> only to view nearby Brands. Filter by <3>Merchandise</3> only to view nearby Merchandise. You can combine Brands and Merchandise for a more specific search.<br/>Tip: Choose one combination for best result.": "Filtrer etter <1>Merker</1> for å se kun nærliggende Merkevarer. Filtrer etter <3>Varer</3> for å se kun nærliggende varer. Du kan kombinere Merker og Varer for et mer spesifikt søk.<br/>Tips: Velg én kombinasjon for best resultat.",
  "Filter Ads for end of day unsold food and beverages. Avoid wasting perfectly good food, save money and support the environment.": "Filtrer Annonser for usolgt mat og drikke ved slutten av dagen. Unngå å kaste bort god mat, spar penger og støtt miljøet.",
  "Brands Nearby": "Merker i Nærheten",
  "Selected Brands": "Utvalgte Merker",
  "Merchandise": "Handelsvarer",
  "Cuisines": "Kjøkken",
  "Show All": "Vis Alt",
  "Meals": "Måltider",
  "Price Level": "Prisnivå",
  "Takeaway": "Takeaway",
  "Type of Bar": "Type Bar",
  "Specialties": "Spesialiteter",
  "Profile": "Profil",
  "Opening Hour": "Åpningstid",
  "Payment Options": "Betalingsalternativer",
  "Parking": "Parkering",
  "Bookmark": "Bokmerke",
  "Filter": "Filter",
  "MAP": "KART",
  "Shopping Malls": "Kjøpesentre",
  "Search Places, Categories, Brands, etc.": "Søk etter steder, kategorier, merker, etc.",
  "Shared With You": "Delt Med Deg",
  "Selected Shopping Center": "Valgt Kjøpesenter",
  "Selected Place": "Valgt Sted",
  "Free WiFi": "Gratis WiFi",
  "Outdoor Seating": "Uteservering",
  "Wheelchair Accessible": "Tilgjengelig for Rullestol",
  "Open Now": "Åpent",
  "Open At": "Åpner Kl",
  "Search": "Søk",
  "<0>You and {{referringUser}} got points</0> that can be used in our lottery, because you signed up with the referral link.": " <0> Du og {{referringUser}} fikk poeng </0> som kan brukes i lotteriet vårt, fordi du registrerte deg med henvisningslenken.",
  "Check out Rewards in the menu <1></1> to spend the points!": "Sjekk Bonuspoeng i menyen <1> </1> for å bruke poengene!",
  "<0>You made an invite and tried to use it yourself.</0>": " <0> Du har laget en invitasjon og prøvde å bruke den selv. </0>",
  "Invite someone else to earn more points. Find the link in the menu <1></1>.": "Inviter noen andre for å tjene flere poeng. Lenken er i menyen <1> </1> .",
  "<0>The invite from {{referringUser}} is only for new users</0>, you were already signed up.": " <0> Invitasjonen fra {{referringUser}} er kun for nye brukere </0> , du var allerede registrert.",
  "<0>The invite from {{referringUser}} has expired</0>, you were already signed up.": " <0> Invitasjonen fra {{referringUser}} har utløpt </0> , du var allerede registrert.",
  "<0>The invite has expired</0>, and you were already signed up.": " <0> Invitasjonen har utløpt </0> , og du var allerede registrert.",
  "<0>You and {{referringUser}} got points</0> that can be used in our lottery, because you claimed a business with the referral link.": " <0> Du og {{referringUser}} fikk poeng </0> som kan brukes i lotteriet vårt, fordi du gjorde krav på en virksomhet med henvisningslenken.",
  "<0>You tried to use your own business referral for {{placeName}}</0>, instead send it to someone else. Then the both of you earn points that can be used in our lottery.": " <0> Du prøvde å bruke din egen forretningshenvisning for {{placeName}} </0> , i stedet send den til noen andre. Da tjener dere begge poeng som kan brukes i vårt lotteri.",
  "Check out Rewards in the menu <1></1> to earn and spend points!": "Sjekk ut Bonuspoeng i menyen <1> </1> for å tjene og bruke poeng!",
  "<0>{{referringUser}} invited you to manage {{placeName}}</0>, but that business is already claimed by someone else.": " <0> {{referringUser}} inviterte deg til å administrere {{placeName}} </0> , men den virksomheten er allerede gjort krav på av noen andre.",
  "If that is incorrect contact support!. Find the link in the menu <1></1>.": "Hvis det er feil, kontakt support!. Finn lenken i menyen <1> </1> .",
  "<0>The invite from {{referringUser}} has expired.</0>": " <0> Invitasjonen fra {{referringUser}} har utløpt. </0>",
  "<0>The invite has expired.</0>": " <0> Invitasjonen har utløpt. </0>",
  "<0>{{referringUser}} got points</0> because you looked at the place shared. You can also share and win points that can be used in our lottery.": "<0> {{referringUser}} fikk poeng </0> fordi du så på det delte stedet. Du kan også dele og vinne poeng som kan brukes i vårt lotteri.",
  "Check out Rewards in the menu <1></1> to learn more!": "Sjekk ut Bonuspoeng i menyen <1> </1> for å lære mer!",
  "<0>You shared and tried to use it yourself</0>. Invite someone else to earn more points.": " <0> Du delte og prøvde å bruke lenken selv </0> . Inviter noen andre for å tjene flere poeng.",
  "Find the link in the menu <1></1>.": "Finn lenken i menyen <1> </1> .",
  "<0>The invite from {{referringUser}} has expired</0>. Invite someone else to earn more points.": " <0> Invitasjonen fra {{referringUser}} har utløpt </0> . Inviter noen andre for å tjene poeng.",
  "<0>The invite has expired</0>. Invite someone else to earn more points.": " <0> Invitasjonen har utløpt </0> . Inviter noen andre for å tjene flere poeng.",
  "<0>{{referringUser}} got points</0> because you looked at the AD Message shared. You can also share and win points that can be used in our lottery.": "<0>{{referringUser}} fikk poeng</0> fordi du så på Annonsen som ble delt. Du kan også dele og vinne poeng som kan brukes i vårt lotteri.",
  "Got It": "Forstått",
  "Type your Home Address": "Skriv inn Hjemmeadressen din",
  "Street": "Gate",
  "Number": "Nummer",
  "My address doesn't have a Street Number.": "Adressen min har ikke et Gatenummer.",
  "Postal Code": "Postnummer",
  "City": "By",
  "Change Home Address": "Endre Hjemmeadresse",
  "Search Address": "Søk Adresse",
  "Type your home address": "Skriv inn hjemmeadressen din",
  "Happy Hour": "Happy Hour",
  "Invalid": "Ugyldig",
  "Number or Postal Code is not correct.": "Nummer eller Postnummer er ikke riktig.",
  "Home": "Hjem",
  "Tap <1><0></0></1> then Scroll Down": "Trykk <1> <0> </0> </1> og bla nedover",
  "Tap <1>Add to Home Screen</1> <3><0></0></3>": "Trykk på <1> Legg til på Hjem-skjermen</1> <3> <0> </0> </3>",
  "Tap <1>Add</1>": "Trykk på <1> Legg til </1>",
  "Tap BROVS Icon on Home Screen": "Trykk på BROVS Ikonet på Startskjermen",
  "Add BROVS icon to Home Screen for easy access.": "Legg til BROVS-ikonet på startskjermen for enkel tilgang.",
  "Done": "Utført",
  "Norwegian": "Norsk",
  "English US": "Engelsk USA",
  "Set new password <1>here</1>.": "Sett nytt passord <1> her </1>.",
  "Create Password <1>here</1>.": "Create Password <1>here</1>.",
  "Sign In": "Logg Inn",
  "Password": "Passord",
  "Sign In Now": "Logg Inn Nå",
  "Forgot your password?": "Glemt Passord?",
  "To Sign In with Mobile Number you need to create a Password.": "For å logge på med mobilnummer må du opprette et passord.",
  "You have previously Signed In with Vipps.": "Du har tidligere logget på med Vipps.",
  "OR": "ELLER",
  "Continue with Mobile Number": "Fortsett med Mobilnummer",
  "How many points do you want to<1></1>convert to Lottery tickets?": "Hvor mange poeng vil du <1> </1> konvertere til lodd?",
  "Get Lottery Tickets": "Skaff Deg Lodd",
  "My Reward Points": "Mine Bonuspoeng",
  "You don't have Reward Points to get Lottery tickets.": "Du har ikke Bonuspoeng for å få lodd.",
  "See how to Earn Reward Points.": "Se hvordan du tjener Bonuspoeng.",
  "One point = One Lottery Ticket": "Ett poeng = Ett lodd",
  "Get Now": "Få Nå",
  "Cancel": "Avbryt",
  "Congratulations!<1></1>You are now participating in the Lottery.": "Gratulerer! <1> </1> Du deltar nå i Lotteriet.",
  "Lottery": "Lotteri",
  "My Tickets In This Lottery": "Mine Lodd I Dette Lotteriet",
  "Get More Lottery Tickets": "Få flere lodd",
  "Previous Lottery": "Forrige Lotteri",
  "Ended on": "Avsluttet på",
  "Congratulations, you are the winner of this Lottery!": "Gratulerer, du er vinneren av dette Lotteriet!",
  "Drawn Winner": "Uttrukket Vinner",
  "New Version Available": "Ny Versjon Tilgjengelig",
  "Update Now": "Oppdater Nå",
  "Options": "Alternativer",
  "Unbookmark": "Fjern Bokmerke",
  "Report Problem": "Rapporter problem",
  "Set New Password": "Lag Nytt Passord",
  "Send Short Code": "Send Kortkode",
  "Forgot your password?. No problem. You can create a new password below.": "Glemt passordet?. Ikke noe problem. Du kan opprette et nytt passord nedenfor.",
  "Check SMS for sent Short Code.": "Sjekk SMS for sendt Kortkode.",
  "Type New Password": "Skriv inn Nytt Passord",
  "New Password": "Nytt Passord",
  "Access {{placeName}}": "Få Tilgang Til {{placeName}}",
  "Expires in {{claimBusinessLinkExpirationTime}} seconds.": "Utløper om {{claimBusinessLinkExpirationTime}} sekunder.",
  "Do you work at<br/>{{placeName}}?": "Arbeider du hos 1{{placeName}}?",
  "Do you know someone who works at<br/>{{placeName}}?": "Kjenner du noen som arbeider hos 1{{placeName}}?",
  "Access Now": "Få Tilgang Nå",
  "Get access and manage the Business Profile for FREE. Update business name, opening hours, contact information, etc. Advertise to a large user audience to get new customers.": "Få tilgang til og administrer Bedriftsprofilen GRATIS. Oppdater bedriftsnavn, åpningstider, kontaktinformasjon osv. Annonser til et stort antall brukere for å få nye kunder.",
  "Manage Now": "Administrer Nå",
  "Invite the owner or someone working here to manage the Business Profile for FREE. When they get access you will receive BROVS Reward Points that you can use in the BROVS lottery.": "Inviter eieren eller noen som jobber her til å administrere Bedriftsprofilen GRATIS. Når de får tilgang vil du motta BROVS bonuspoeng som du kan bruke i BROVS-lotteriet.",
  "Invite by SMS": "Inviter med SMS",
  "Hi :-) I invite you to manage {{name}} in BROVS for FREE.\nBROVS is the new website to browse for ads, products and services at local businesses.\nFashion - Food & Drink - Rewards - and more.\n{{url}}": "Hei :-) Jeg inviterer deg til å administrere {{name}} i BROVS GRATIS.\nBROVS er det nye nettstedet for å søke etter annonser, produkter og tjenester hos lokale bedrifter.\nMote - Mat og drikke - Belønninger - og mer.\n{{url}}",
  "Hi :-) I invite you to manage {{name}} in BROVS for FREE.\nBROVS is the new website to browse for ads, products and services at local businesses.\nFashion - Food & Drink - Rewards - and more.": "Hei :-) Jeg inviterer deg til å administrere {{name}} i BROVS GRATIS.\nBROVS er det nye nettstedet for å søke etter annonser, produkter og tjenester hos lokale bedrifter.\nMote - Mat og drikke - Belønninger - og mer.",
  "Send Referral": "Send vervelenke",
  "SMS": "SMS",
  "Other": "Annet",
  "Open": "Åpent",
  "Closes": "Stenger",
  "Closed": "Lukket",
  "Opens": "Åpner",
  "Report a problem related to this business?": "Rapportere et problem relatert til denne virksomheten?",
  "We appreciate any kind of feedback that could make our service better. Contact Support to report a problem. Thank you in advance.": "Vi setter pris på alle slags tilbakemeldinger som kan gjøre tjenesten vår bedre. Kontakt kundestøtte for å rapportere et problem. Takk på forhånd.",
  "This profile is managed by<br/>.": "Denne profilen administreres av1.",
  "Verified Profile": "Verifisert Profil",
  "Overview": "Oversikt",
  "Menu": "Meny",
  "Contact": "Kontakt",
  "Share": "Del",
  "Interested in Ads?": "Interessert i Annonser?",
  "You have expressed interest in Ads from this business.": "Du har vist interesse for Annonser fra denne virksomheten.",
  "Let Us Know": "Gi Oss Beskjed",
  "Brands not added.": "Merker ikke lagt til. ",
  "Categories and Brands": "Kategorier og Merker",
  "Shopping Mall": "Kjøpesenter",
  "Website": "Nettsted",
  "Webshop": "Nettbutikk",
  "Not added": "Ikke lagt til",
  "WiFi": "WiFi",
  "Free": "Gratis",
  "Yes": "Ja",
  "No": "Nei",
  "Suitability": "Egnet for",
  "Accessibility": "Tilgjengelighet",
  "Managed by": "Administrert av",
  "Earn Reward Points by inviting the Owner or an Employee to manage the Business Profile.": "Tjen Bonuspoeng ved å invitere Eieren eller en Ansatt til å administrere Bedriftsprofilen.",
  "Invite or Manage": "Inviter eller Administrer",
  "Menu has not been added.": "Menyen er ikke lagt til.",
  "Opening Hours": "Åpningstider",
  "Monday": "Mandag",
  "Tuesday": "Tirsdag",
  "Wednesday": "Onsdag",
  "Thursday": "Torsdag",
  "Friday": "Fredag",
  "Saturday": "Lørdag",
  "Sunday": "Søndag",
  "Categories": "Bransje",
  "Floor": "Etasje",
  "Accept <1>User Terms of Service</1> and <3>Privacy Policy</3>.": "Godta <1>Brukervilkår - Person</1> og <3>Personvernregler</3>.",
  "Privacy Policy": "Personvernerklæring",
  "Terms of Service": "Brukervilkår - Person",
  "Date of Birth": "Fødselsdato",
  "Day": "Dag",
  "Month": "Måned",
  "Year": "År",
  "Photo": "Bilde",
  "Invite Friends to join": "Inviter Venner",
  "Hi :-) Check out BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.\n{{url}}": "Hei :-) Sjekk ut BROVS, det nye nettstedet for å søke etter annonser, produkter og tjenester hos lokale bedrifter. Mote - Mat og drikke - Belønninger - og mer.\n{{url}}",
  "BROVS": "BROVS",
  "Hi :-) Check out BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.": "Hei :-) Sjekk ut BROVS, det nye nettstedet for å se annonser, produkter og tjenester hos lokale forretninger. Mote - Mat og Drikke - Bonuspoeng - og mer.",
  "When your friends Sign's Up you will receive BROVS Points that you can use in the BROVS lottery. Invite as many friends as you like.": "Når vennene dine Registrerer seg vil du motta BROVS-poeng som du kan bruke i BROVS-lotteriet. Inviter så mange venner du vil.",
  "Business Referrals": "Forretningshenvisninger",
  "Text Message / SMS": "Tekstmelding / SMS",
  "(Only Norwegian Numbers)": "(Kun Norske Mobilnumre)",
  "Points": "Poeng",
  "Invite Business Owner or Employee": "Inviter Virksomhetseier eller Ansatt",
  "Congratulations!": "Gratulerer!",
  "You earned BROVS Points just by signing up.<br/><br/>You can use your reward points for tickets in the BROVS lottery.": "Du tjente BROVS-poeng bare ved å registrere deg. Du kan bruke bonuspoengene dine for lodd i BROVS-lotteriet.",
  "Earn Reward Points": "Tjen Bonus Poeng",
  "Friend Referrals": "Vennehenvisninger",
  "Shared Places": "Delte Steder",
  "Shared AD Messages": "Delte Annonser",
  "Sign Up Bonus": "Registreringsbonus",
  "Invite Friends": "Inviter Venner",
  "When you share and refer you get points. These points can be used to participate in our monthly lotteries.": "Når du deler og henviser får du poeng. Disse poengene kan brukes til å delta i våre månedlige lotterier.",
  "Number of Contributions": "Antall Bidrag",
  "Expiration of Points by 31. December": "Poeng om utløper 31. desember",
  "My Rewards": "Mine Bonuspoeng",
  "Use AD Messages Share Icon": "Bruk Dele-ikonet på Annonser",
  "Use Place Share Icon": "Bruk Ikon for Stedsdeling",
  "Use Reward Points": "Bruk Bonuspoeng",
  "Account": "Konto",
  "Add your E-mail for communication and promotions.": "Legg til din e-post for kommunikasjon og kampanjer.",
  "Verified": "Verifisert",
  "Not Verified.": "Ikke Verifisert.",
  "Send Short Code Verification to E-mail.": "Send kortkode til e-post.",
  "Short Code Verification sent to above E-mail.": "Kortkode for verifisering er sendt til din e-post.",
  "Have you not received an E-mail with the Short Code Verification?": "Har du ikke mottatt en e-post med kortkode?",
  "Language": "Språk",
  "Change Password": "Endre Passord",
  "Add Password": "Legg til Passord",
  "Are you sure you want to change Password?": "Er du sikker på at du vil endre Passord?",
  "Personal Information *": "Personlig Informasjon *",
  "Gender": "Kjønn",
  "Invalid Mobile Number.": "Ugyldig Mobilnummer.",
  "Change if not correct.": "Endre hvis ikke korrekt.",
  "Verified.": "Verifisert.",
  "Check your SMS for Short Code": "Sjekk SMS-ene din for kortkode.",
  "Confirm Mobile Number": "Bekreft Mobilnummer",
  "Crop width or height is not set": "Bredde eller høyde på utsnitt er ikke satt",
  "Could not get canvas context": "Kunne ikke få tak i lerretskontekst",
  "Canvas is empty": "Lerret er tomt",
  "Image not found.": "Bilde ikke funnet.",
  "Crop dimensions not set.": "Beskjæringsdimensjoner ikke satt.",
  "Error decoding image.": "Feil ved dekoding av bilde.",
  "Profile Photo": "Profilbilde",
  "Upload New": "Last opp Nytt",
  "Upload": "Last opp",
  "Delete": "Slett",
  "Crop Photo": "Beskjær Bilde",
  "Sure you want to delete?": "Er du sikker på at du vil slette?",
  "Sign Out": "Logg Ut",
  "Delete Account": "Slett Konto",
  "Are you sure you want to delete Your Account?": "Er du sikker på at du vil slette Kontoen din?",
  "Delete My Account": "Slett Kontoen Min",
  "Hi :-) you might have an interest in this ad {{adTitle}} from {{placeName}} in BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.\n{{url}}": "Hei :-) du kan være interessert i denne annonsen {{adTitle}} fra {{placeName}} i BROVS, det nye nettstedet for å søke etter annonser, produkter og tjenester hos lokale bedrifter. Mote - Mat og drikke - Belønninger - og mer.\n{{url}}",
  "Hi :-) you might have an interest in this ad {{adTitle}} {{adDescription}} from {{placeName}} in BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.\n{{url}}": "Hei :-) du er kanskje interessert i denne annonsen {{adTitle}} {{adDescription}} fra {{placeName}} i BROVS, det nye nettstedet for å søke etter annonser, produkter og tjenester hos lokale bedrifter. Mote - Mat og drikke - Belønninger - og mer.\n{{url}}",
  "Share this AD Message": "Del denne Annonsen",
  "Hi :-) check out {{name}} in BROVS, the new website to browse for ads, products and services at local businesses. Fashion - Food & Drink - Rewards - and more.\n{{url}}": "Hei :-) sjekk ut {{name}} i BROVS, det nye nettstedet for å søke etter annonser, produkter og tjenester hos lokale bedrifter. Mote - Mat og drikke - Belønninger - og mer.\n{{url}}",
  "Share this profile": "Del denne profilen",
  "Manage Business": "Administrer Virksomhet",
  "Rewards": "Bonus",
  "Invite a friend": "Inviter en venn",
  "Business": "Virksomhet",
  "The invite has expired.": "Invitasjonen har utløpt.",
  "The invite from {{firstName}} {{lastName}} has expired.": "Invitasjonen fra {{firstName}} {{lastName}} har utløpt.",
  "Sign up NOW and the both of you earn points to WIN our lottery.<br/><br/>The invite expires in {{invitationDaysLeft}} days.": "Registrer deg NÅ, så tjener dere begge poeng for å VINNE lotteriet vårt.<br/><br/>Invitasjonen utløper om {{invitationDaysLeft}} dager.",
  "Sign up NOW to claim and manage your business and the both of you earn points that can be used in our lottery.<br/><br/>The invite expires in {{invitationDaysLeft}} days.": "Registrer deg NÅ for å gjøre krav på og administrere virksomheten din, og begge tjener poeng som kan brukes i lotteriet vårt.<br/><br/> Invitasjonen utløper om {{invitationDaysLeft}} dager.",
  "Sign up NOW to see it and the both of you earn points that can be used in our lottery.<br/><br/>The invite expires in {{invitationDaysLeft}} days.": "Meld deg på NÅ for å se, og dere tjener begge poeng som kan brukes i vårt lotteri.<br/><br/>Invitasjonen utløper om {{invitationDaysLeft}} dager.",
  "Sign up NOW to view the AD Message.<br/><br/>You and the person inviting you will both earn Reward Points that can be used in our lottery.<br/><br/>The invite expires in {{invitationDaysLeft}} days.": "Registrer deg NÅ for å se Annonsen.<br/><br/>Du og personen som inviterer deg vil begge tjene belønningspoeng som kan brukes i vårt lotteri.<br/><br/>Invitasjonen utløper om {{invitationDaysLeft}} dager.",
  "Browse ads, products and<br/>services at local businesses": "Bla gjennom annonser, produkter og<br/>tjenester hos lokale virksomheter",
  "invites you to use this App.": "inviterer deg til å bruke denne appen.",
  "invites you to manage your business {{businessName}}.": "inviterer deg til å administrere din virksomheten {{businessName}}.",
  "wants to share with you": "ønsker å dele med deg",
  "Fashion": "Fashion",
  "Food & Drink": "Mat & Drikke",
  "Easy and FREE Sign In": "Enkel og GRATIS Innlogging"
}
